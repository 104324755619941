<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet>
          <template v-slot:title>
            {{ $t("marks.title") }}
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            <div>
              <SubjectInfo :subject="subjectInfo"></SubjectInfo>
            </div>

            <b-tabs pills card id="" v-model="currentQuarter">
              <b-tab>
                <template v-slot:title>
                  I четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="1" :show-edit-button="true" ref="groupSubjectQ1"></MarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  II четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="2" :show-edit-button="true" ref="groupSubjectQ2"></MarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  III четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="3" :show-edit-button="true" ref="groupSubjectQ3"></MarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  IV четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="4" :show-edit-button="true" ref="groupSubjectQ4"></MarksByQuarter>
              </b-tab>     
              <b-tab>
                <template v-slot:title>
                  КТП
                </template>
                <SubjectEduPlan :group-subject-id="groupSubjectId" :show-edit-button="true" ref="subjectEduPlan"></SubjectEduPlan>
              </b-tab>   
              <b-tab>
                <template v-slot:title>
                  Замена
                </template>
                <SubstituteTeacher :group-subject-id="groupSubjectId" :quarter="4" :show-edit-button="true" ref="substituteTeacher"></SubstituteTeacher>
              </b-tab>
              <b-tab @click="reloadInformationAboutPhysicalActivityIndicators" v-if="showPhysicalActivity">
                <template v-slot:title>
                  {{ $t("informationAboutPhysicalActivityIndicators.title") }}
                </template>
                <InformationAboutPhysicalActivityIndicators :group-id="'-1'" ref="informationAboutPhysicalActivityIndicatorsGS"></InformationAboutPhysicalActivityIndicators>
              </b-tab>
            </b-tabs>            
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
/*  table, th, td {
    border: 1px solid black;
  }*/
.th-button {
  padding-bottom: 10px !important;
}

.exit-hw {
  padding: 2px !important;
  color: #fff;
  background-color: #fc0758;
  border-color: #f40252;
}

.change-hw-div > label {
  display: inline-block;
  margin-right: 10px;
}

.th-data {
  min-height: 50px;
  min-width: 50px;
  padding-top: 10px;

  max-height: 100px;
  overflow-y: auto;
}

.th-button {
  background-color: #f7f8fa;
}

.hw-title {
  margin-top: 20px;
  font-style: italic;
  font-weight: bold;
  padding: 5px;
  background-color: #384;
  color: white;
  white-space: normal !important;
}

.lesson-content-title{
  font-style: italic;
  font-weight: bold;
  padding: 5px;
  background-color: #336388;
  color: white;
  white-space: normal !important;
}

/*.first-column{
  position: absolute;
  left: 0;
  width: 20px;
}

.second-column{
  position: absolute;
  left: 20px;
  width: 200px;
}

.table-container {
  position: relative;
  overflow: scroll;
}*/

.view {
  margin: auto;
  width: 100%;
}

.table-container {
  position: relative;
  overflow: auto;
  /*white-space: nowrap;*/
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-column {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  left: 0px;
}

.second-column {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 20px;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import SubjectInfo from "./partials/SubjectInfo.vue";
import MarksByQuarter from "./partials/MarksByQuarter.vue";
import SubjectEduPlan from "./partials/SubjectEduPlan.vue";
import SubstituteTeacher from "./partials/SubstituteTeacher.vue";
import ApiService from "@/common/api.service";
import InformationAboutPhysicalActivityIndicators from "@/views/pages/edu/schools/group/partials/InformationAboutPhysicalActivityIndicators.vue";
export default {
  name: "marks",
  components: {
    MarksByQuarter,
    SubjectInfo,
    SubjectEduPlan,
    SubstituteTeacher,
    KTPortlet,
    InformationAboutPhysicalActivityIndicators
  },
  data() {
    return {
      groupSubjectId: this.$route.params.id + "",
      subjectInfo: {
        subjectName: '',
        groupName: '',
        teacherName: '',
        groupId: -1
      },
      currentQuarter: 0,
      showPhysicalActivity: false,
      showPhysicalActivitySubjectCode: 'phy'
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    
    reloadData() {
      let $this = this;

      ApiService.querySecured("groupSubjects/info", {
        params: {id: this.groupSubjectId}
      })
          .then(({data}) => {
            $this.subjectInfo = data;
            if (data.subjectCode === $this.showPhysicalActivitySubjectCode) {
              $this.showPhysicalActivity = true;
            }

          })
          .catch(({response}) => {
            console.log(response);
          });

      ApiService.querySecured("settings/currentQuarter", {})
          .then(({data}) => {
            $this.currentQuarter = data - 1;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    reloadInformationAboutPhysicalActivityIndicators(){
      this.$refs['informationAboutPhysicalActivityIndicatorsGS'].reloadData(this.subjectInfo.groupId);
    },
  }
};
</script>
