<template>
  <div class="row">
    <div class="col-lg-12">
<!--      <b-button @click="creat" class="btn btn-success">{{ $t("common.new") }}</b-button>-->
    </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left" rowspan="2">
            {{ $t("informationAboutPhysicalActivityIndicators.list.name") }}
          </th>
          <th class="text-left" colspan="4">
            {{ $t("informationAboutPhysicalActivityIndicators.list.firstHalf") }}
          </th>
          <th class="text-left" colspan="4">
            {{ $t("informationAboutPhysicalActivityIndicators.list.secondHalf") }}
          </th>
          <th class="text-left" rowspan="2"></th>
        </tr>
        <tr>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginRun") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginPullUp") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginStandingLongJump") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginMark") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndRun") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndPullUp") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndStandingLongJump") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndMark") }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.studentName }}</td>
          <td>{{ item.phyActIndicatorBeginRun }}</td>
          <td>{{ item.phyActIndicatorBeginPullUp }}</td>
          <td>{{ item.phyActIndicatorBeginStandingLongJump }}</td>
          <td>{{ displayMark(item.phyActIndicatorBeginMark) }}</td>
          
          <td>{{ item.phyActIndicatorEndRun }}</td>
          <td>{{ item.phyActIndicatorEndPullUp }}</td>
          <td>{{ item.phyActIndicatorEndStandingLongJump }}</td>
          <td>{{ displayMark(item.phyActIndicatorEndMark) }}</td>
          <td>
            <b-button
                type="submit"
                @click="edit(item)"
                variant="primary"
            >
              {{ $t("common.edit") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <b-modal id="InformationAboutPhysicalActivityIndicators-modal" ref="InformationAboutPhysicalActivityIndicators-modal">
          <template v-slot:modal-title>
            {{ $t("common.new") }}
          </template>
          <b-form>
            <div>
              {{ $t("informationAboutPhysicalActivityIndicators.list.name") }} - {{form.studentName}}
            </div>
            <h6>{{ $t("informationAboutPhysicalActivityIndicators.list.firstHalf") }}</h6>
            <div class="separator separator-dashed my-10"></div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginRun") }}
              </template>
              <b-form-input
                  v-model="form.phyActIndicatorBeginRun"
                  :state="modelState.phyActIndicatorBeginRun"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorBeginRun }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginPullUp") }}
              </template>
              <b-form-input
                  v-model="form.phyActIndicatorBeginPullUp"
                  :state="modelState.phyActIndicatorBeginPullUp"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorBeginPullUp }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginStandingLongJump") }}
              </template>
              <b-form-input
                  v-model="form.phyActIndicatorBeginStandingLongJump"
                  :state="modelState.phyActIndicatorBeginStandingLongJump"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorBeginStandingLongJump }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorBeginMark") }}
              </template>
              <b-form-select
                  v-model="form.phyActIndicatorBeginMark"
                  :options="marksList"
                  :state="modelState.phyActIndicatorBeginMark">                
              </b-form-select>              
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorBeginMark }}</div>
            </b-form-group>

            <h6>{{ $t("informationAboutPhysicalActivityIndicators.list.secondHalf") }}</h6>
            <div class="separator separator-dashed my-10"></div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndRun") }}
              </template>
              <b-form-input
                  v-model="form.phyActIndicatorEndRun"
                  :state="modelState.phyActIndicatorEndRun"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorEndRun }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndPullUp") }}
              </template>
              <b-form-input
                  v-model="form.phyActIndicatorEndPullUp"
                  :state="modelState.phyActIndicatorEndPullUp"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorEndPullUp }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndStandingLongJump") }}
              </template>
              <b-form-input
                  v-model="form.phyActIndicatorEndStandingLongJump"
                  :state="modelState.phyActIndicatorEndStandingLongJump"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorEndStandingLongJump }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutPhysicalActivityIndicators.list.phyActIndicatorEndMark") }}
              </template>
              <b-form-select
                  v-model="form.phyActIndicatorEndMark"
                  :options="marksList"
                  :state="modelState.phyActIndicatorEndMark">
              </b-form-select>
              <div class="invalid-feedback">{{ modelSaveErrors.phyActIndicatorEndMark }}</div>
            </b-form-group>
            
          </b-form>          

          <template v-slot:modal-footer>
            <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
              {{ $t("common.save") }}
            </b-button>
            <b-button type="reset" @click="onReset" variant="danger">
              {{ $t("common.cancel") }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<style>
.separator.separator-dashed {
  border-bottom: 1px dashed #000000;
}
.separator {
  height: 0;
}
</style>

<script>
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "InformationAboutPhysicalActivityIndicators",
  components: {
  },
  props:{
    groupId:String
  },
  data() {
    return {
      modalName: "InformationAboutPhysicalActivityIndicators-modal",
      items: [],
      groupIdMut: 0,
      form: {
        studentId: 0,
        studentName: "",
        socUsefulWorkFirstHalf: "",
        socUsefulWorkSecondHalf: "",
        socUsefulWorkContent: "",
      },
      modelState: {},
      modelSaveErrors: {},
      marksList: DictionariesService.getMarksForPhysicalActivity(),
    };
  },
  mounted() {    
    this.groupIdMut = this.groupId;
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      
      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.groupId;

      ApiService.postSecured("journal/additional/saveInformationAboutPhysicalActivityIndicators", this.form)
          .then(function () {
            $this.$refs[$this.modalName].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }else{
              alert("Ошибка, перезайдите или обратитесь к администратору");
            }
          }).finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[this.modalName].hide();
    },
    reloadData(groupId) {
      if(typeof groupId !== "undefined"){
        this.groupIdMut = groupId
      }
      
      ApiService.querySecured("journal/additional/informationAboutPhysicalActivityIndicators", {
        params: {groupId: this.groupIdMut}
      }).then(({data}) => {
        this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    edit(item) {
      this.form = item;
      this.$refs[this.modalName].show();
    },
    displayMark(mark){
      return DictionariesService.displayQuarterMark(mark);
    },
  }
};
</script>
